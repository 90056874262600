import React from 'react';
import './header.styles.scss';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

export const Header = () => (
  <div className='header-content'>
    <div className='content-1'>
      <p className='text-style-1'>Decentralized Identity.</p>
      <p className='text-style-2'>Frictionless, Secure, and Trustworthy.</p>
    </div>
    <div className='content-2'>
      <p className='text-style-3'>
      Prevent identity frauds, comply with data privacy laws and foster customer trust, with EarthID&apos;s award-winning decentralized identity and verifiable credentials platform.
      </p>
    </div>
    <div className='header-button'>
      <button className='button-header_1'>
        <Link to='/solution/platform' className='explore-button'>
          <p className='border-para_1'>EXPLORE EARTHID PLATFORM</p>
        </Link>
      </button>
      <button className='button-header_2'>
        <Link to='/ssi' className='explore-button'>
          <p className='border-para_2'>Explore SSI</p>
        </Link>
      </button>
    </div>
  </div>
);


export const HeaderOverlap = props => {
  const { announcement } = props;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,           // Enable autoplay
    autoplaySpeed: 3000,      // Set autoplay interval in milliseconds (e.g., 3000ms or 3s)
    arrows: false,
  };

  //const limitedAnnouncements = announcement.slice( -3);
// Get the last 3 announcements and reverse their order to show the latest first
const limitedAnnouncements = announcement.slice(-3).reverse();

  return (
    <Slider {...settings}>
      {limitedAnnouncements.map((announcement, index) => (
        <div key={index} className='overlap-content'>
          <div className='overlap-text'>
            <p className='overlap-text_1'>{announcement.title}</p>
            <p className='overlap-text_2'>{announcement.sub_title}</p>
            <p className='overlap-text_3'>{announcement.description}</p>
            <p className='overlap-text_3'><a href={announcement.url} className='overlap-text_3_link' target='_blank' rel="noreferrer">Read More...</a></p>
          </div>
          {/* <div className='overlap-btn'>
            <a className='overlap-btn_1' href={announcement.url} target='_blank' rel="noreferrer">Explore</a>
          </div> */}
        </div>
      ))}
    </Slider>
  );
}