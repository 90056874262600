import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBoxTechno } from '../techno.component';
import phoneImg1 from '../../../assest/phoneImg/Face Based.png';
import phoneImg2 from '../../../assest/phoneImg/Liveness Detection.png';
import './face-based.styles.scss';

export const FaceBased = () => (
  <div className='blogs-main'>
    <NavBar />
    {/* <HeaderHalf title='Face-Based Biometrics' uris='Face-Based Biometrics' /> */}
    <HeaderHalf title='Biometrics' uris='Biometrics' />
    <OverlapBoxTechno
      img={phoneImg1}
      title='Nextgen Facial Recognition For Higher Accuracy And Security.'
    >
      <p>
        EarthID uses future generation biometric technology to offer its users the most versatile
        human verification process. Our facial recognition system enables us to verify identity
        documents against the person making the transaction. Hence, adding an extra layer of
        protection against stolen IDs and impersonation attacks.
      </p>
      <p>
        It&apos;s a straightforward process for our clients, they simply take a selfie. During the
        time spent taking the selfie, EarthID’s educated AI naturally chooses the best picture out
        of the progressive still shots, to perform progressed facial checking. The facial biometrics
        are compared with the photograph on the ID record, ensuring legitimacy.
      </p>
      <p>
        Our non-contact procedure powered by facial recognition technology offers a safe and
        reliable solution ensuring seamless client onboarding. To know more about our solution, talk
        to our experts.
      </p>
    </OverlapBoxTechno>
    
    {/* Added new block of liveness detection here below */}
    <OverlapBoxTechno
      img={phoneImg2}
      title='Put An End To Face Biometric Spoofing With Liveness Detection Feature.'
    >
      <p>
        With increasing online fraud and identity theft, identity authentication service providers
        like EarthID need to ensure that there is no scope left for a security gap in the prevalent
        biometric systems. Even a minor security gap can lead to fraudsters successfully spoofing a
        facial recognition system.
      </p>
      <p>
        Owing to the criticality of the security gaps of current biometric systems, EarthID’s
        Liveness Detection technology protects users from spoofing attempts in unsupervised
        situations. Our anti-spoofing biometric algorithms are so powerful that our system cannot be
        easily fooled by spoofing techniques. Facial Liveness detection aims to analyze facial
        reactions to ensure the captured biometric information is from a living/moving subject.
      </p>
      <p>
        EarthID’s Liveness Detection Authentication offers reliable anti-spoofing against biometric
        frauds. To experience frictionless authentication with EarthID, contact our experts and get
        to know more about our robust system.
      </p>
    </OverlapBoxTechno>
    <Getstarted/>
    <Footer />
  </div>
);

//component
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-face'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Technology / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
