import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBoxTechno } from '../techno.component';
import phoneImg from '../../../assest/phoneImg/passwordless authentication-02.png';
import './passwordless.styles.scss';

export const Passwordless = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Passwordless Authentication' uris='Passwordless Authentication' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Creating A World Without Passwords And A Future Without Data Breaches.'
    >
      <p>
      With passwords being the easiest target for identity hackers to penetrate security defenses, it is time to go passwordless. Passwordless Authentication is the future of authentication which no more requires users to either remember complex passwords or handle password resets frequently.      </p>
      <p>
      Passwordless Authentication relies on proof of identity done based on the possession of something that uniquely identifies the user. And, this can be a registered mobile device or a user’s biometric signature (i.e face or palm). Passwordless Authentication provides increased security, improved user experience, along with reduced IT costs (with a fewer password reset policies).      </p>
      <p>
      With EarthID’s Passwordless Authentication enable users to access their accounts without needing to input knowledge-based passwords. Replace passwords with faster multi-factor authentication and advanced biometrics for improved user experience.       </p>
    </OverlapBoxTechno>
    <Getstarted/>
    <Footer />
  </div>
);

//dafh
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-pl'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Technology / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
