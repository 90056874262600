
import React from 'react';
import { useHistory } from "react-router";
import './getstarted.styles.scss';
import { Link } from 'react-router-dom';

export const Getstarted = () => {
    const history = useHistory();

    const handleRequestDemo = e => {
        history.push("/request-sales");
        window.scrollTo(0, 0);
      };

  return (
    
      
      <div className='footer-sec'>
        <p className='get-started text-bold font-weight-bold'>Get Started</p>
        <p className='earthid-footer-desc'>
        Let an EarthID expert show you how easy it is to integrate our decentralized identity and verifiable credential solutions for your business needs. Request more information here and we’ll be in touch shortly.
      </p>
        <button className='footer-btn_1' onClick={handleRequestDemo}>
          <Link className='links'>
            <p className='btn-title'>Talk to an Expert</p>
          </Link>
        </button>
      </div>
      
      
    
  );
};