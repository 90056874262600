import React from 'react';
import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import '../../resourcespage/blogs/blogs.styles.scss';
import '../../resourcespage/webinars/webinars.styles.scss';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import './news.styles.scss';
//import capture from '../../../assest/16 - About - Latest News/Capture.png';
import Slider from "react-slick";
import "./../../../../node_modules/slick-carousel/slick/slick.css";
import "./../../../../node_modules/slick-carousel/slick/slick-theme.css";
import award from "../../../assest/16 - About - Latest News/ifta_2019.jpg";
import award1 from "../../../assest/16 - About - Latest News/award1.jpeg";

export const LatestNews = props => {
  
  const slickSettings = {
    speed: 500,
    autoPlay: false,
    arrows:false,
    dots:true,
    slidesToScroll: 1,
    slidesToShow: 3,
    infinite:false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          arrows: false,
          // infinite: true,
          slidesToScroll: 1,
          slidesToShow: 1,
          swipeToSlide: true,
          autoplay:true
        }
      },
      {
        breakpoint: 992,
        settings: 'unslick',
      }
    ]
  };

  const { newsCards, pressCards, loading } = props;
  return (
    <div className='blogs-main'>
      <NavBar />
      <HeaderHalf title='Latest News' uris='Latest News' />
      <OverlapBox title='Press Releases' className='overlap-box-news-1'>
        <div>
          {loading === true ? (
            <h1>Loading the content</h1>
          ) : (
              <div className="w-100">
                <Slider {...slickSettings}>
                  {
                    newsCards.map(newsCard => (
                      <NewsCard key={newsCard.id} title={newsCard.title} img={newsCard.coverImage} date={newsCard.publishedAt} nlink={newsCard.url} />
                    ))
                  }
                </Slider>
              </div>
            )}

        </div>
      </OverlapBox>
      <OverlapBoxRest title='Press Coverage'>
        <div>
          {loading === true ? (
            <h1>Loading the content</h1>
          ) : (
              <Slider {...slickSettings}>
                {
                  pressCards.map(pressCard => (
                    <PressCard key={pressCard.id} publisher={pressCard.title} title={pressCard.description} img={pressCard.logoImage} date={pressCard.publishedAt} plink={pressCard.url} />
                  ))
                }
              </Slider>
            )}

        </div>
      </OverlapBoxRest>
      <OverlapBoxRest title='Awards'>
        <div className='grid-for-news-publishers1 text-center'>
          <img src={award1} alt="" />
        </div>
        <p className='award-image-title'>EarthID won the &lsquo;Blockchain Innovation of the Year 2022&rsquo; award at the 10th Digital Leaders 100 Awards at Shard London.</p>
        <div className='grid-for-news-publishers2 text-center'>
          <img src={award} alt="" />
        </div>
        <p className='award-image-title'>Finalist at India FinTech Awards (IFTA) 2019 for Best Innovation in RegTech and Fintech Startup of The Year.</p>
      
      </OverlapBoxRest>
      <Getstarted/>
      <Footer />
    </div>
  );

}

const NewsCard = ({ img, title, date, nlink }) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  var dateParse = new Date(date);
  const url = process.env.REACT_APP_IMG_NEWS_URL;
  return (
    <div className='blog-div'>
      <img src={`${url}${img}`} alt='anImg' className='img-for-blog img-for-news' />
      <p className='title-for-news'>{title}</p>
      <p className='dateFieldNews'>{dateParse.toLocaleDateString('en-US', options)}</p>
      <button className='btn-blog'>
        <a href={nlink} target="blank">
          View Press Release
        </a>
      </button>
    </div>
  );
};


export const OverlapBoxRest = props => {
  return (
    <div className='overlap-box-for-news'>
      <p className='box-title-for-news'>{props.title}</p>
      {props.children}
    </div>
  );
};
const PressCard = ({ publisher, date, title, plink, img }) => {
  const purl = process.env.REACT_APP_IMG_PRESS_URL;
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  var dateParse = new Date(date);
  return (
    <div>
      <div>
        <img src={`${purl}${img}`} alt='anImg' className='img-for-press' />
      </div>
      <div className='press-div'>
        <a href={plink} className='anchor-tag-publisher'>{title}
          <p className='publisher'>{publisher}</p>
          <p className='dateFieldPublisher'>{dateParse.toLocaleDateString('en-US', options)}</p>
        </a>
      </div>
    </div>
  );
};

//news
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-news'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          About / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
