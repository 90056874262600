import React from 'react';
import dl_img from '../../../assest/Home/Decentral_new1x.png';
import zkp_img from '../../../assest/Home/Zkp_new1x.png';
import biometric_img from '../../../assest/Home/biometrics_new1x.png';
import passwd_img from '../../../assest/Home/Passless_new1x.png';
import './face-scan-two.styles.scss';

export const FaceScanTwo = () => (
  <div className='faceScan-content xx-ripple'>
    <p className='faceScan-content_title'>Award-Winning Platform Using Innovative Technologies</p>
    <p className='faceScan-content_desc'>
    As a trusted identity platform, EarthID thrives to bring intelligence to identity. Our global decentralized identity platform, powered by distributed ledger technology, offers frictionless exchange of identity information for both issuance and verification purposes. EarthID helps you issue digital identity and credentials which are reusable, portable and interoperable, resulting in massive cost and time savings.
    </p>
    <p className='faceScan-content_feat'>Technology</p>
    <div className='faceScan-content-img'>
      <div className='faceScan-link-block'>
        <div>
          <button className='btn-animate'>
            <a href='/technology/biometrics'>
              <img src={biometric_img} alt='icons_face-content' className='icon-face-content' />
            </a>
          </button>
        </div>
        <p className='icon-face-title'>Biometrics</p>
      </div>
      <div className='faceScan-link-block'>
        <div>
          <button className='btn-animate'>
            <a href='/technology/distributed-ledger'>
              <img src={dl_img} alt='icons_face-content' className='icon-face-content' />
            </a>
          </button>
        </div>
        <p className='icon-face-title'>Distributed Ledger</p>
      </div>
      <div className='faceScan-link-block'>
        <div>
          <button className='btn-animate'>
            <a href='/technology/passwordless'>
              <img src={passwd_img} alt='icons_face-content' className='icon-face-content' />
            </a>
          </button>
        </div>
        <p className='icon-face-title'>Passwordless</p>
      </div>
      <div className='faceScan-link-block'>
        <div>
          <button className='btn-animate'>
            <a href='/technology/zero-knowledge-proof'>
              <img src={zkp_img} alt='icons_face-content' className='icon-face-content' />
            </a>
          </button>
        </div>
        <p className='icon-face-title'>Zero-Knowledge Proof</p>
      </div>
      {/* <div className='faceScan-link-block'>
        <div>
          <button className='btn-animate'>
            <a href='/technology/zero-knowledge-proof'>
              <img src={zkp_img} alt='icons_face-content' className='icon-face-content' />
            </a>
          </button>
        </div>
        <p className='icon-face-title'>Zero-Knowledge Proof</p>
      </div> */}
    </div>
    
    
  </div>
);
