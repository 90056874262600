import React, { useEffect } from 'react';
import HomePage from './pages/homepage/homepage.component';
import { Route, Switch } from 'react-router-dom';
import SolutionPage from './pages/solutionpage/solutionpage.component';
import ResourcesPage from './pages/resourcespage/resources.component';
import AboutPage from './pages/aboutpage/aboutpage.component';
import TechnoPage from './pages/technopage/techno.component';
import PrivacyPage from './pages/technopage/privacy/privacy.component';
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga'


import SubscriberPage from './pages/subscriberpage/subscriber.component';
import SSIPage from './pages/ssi-page/ssi-page.component';
import ErrorPage from './pages/errorpage/error.component';
import RequestSalesPage from './pages/requestsalespage/requestsalespage.component';
import MobileAppPrivacy from './pages/technopage/privacy/mobile.application.end.user.license';
import AppPrivacy from './pages/technopage/privacy/app.privacy-policy';
import TermsAndConditions from './pages/technopage/privacy/terms.and.conditions';
import AppDownload from './pages/technopage/privacy/appDownload';
import WoccuAppDownload from './pages/technopage/privacy/woccuAppDownload';
import AppDownloadMasterCard from './pages/technopage/privacy/appDownload-m';
import AppDownloadWoccu from './pages/technopage/privacy/appDownload-w';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO)
const browserHistory = createBrowserHistory()
browserHistory.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search)
})



const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <Switch>
      <Route exact path='/' component={HomePage} />
      <Route path='/solution' component={SolutionPage} />
      <Route path='/resources' component={ResourcesPage} />
      <Route path='/about' component={AboutPage} />
      <Route path='/technology' component={TechnoPage} />
      <Route path='/subscriber' component={SubscriberPage} />
      <Route path='/ssi' component={SSIPage} />
      <Route path='/error' component={ErrorPage} />
      <Route path='/request-sales' component={RequestSalesPage} />
      <Route path='/privacy-policy' component={PrivacyPage} />
      <Route path='/mobile-application-end-user-licence' component={MobileAppPrivacy} />
      <Route path='/app-privacy-policy' component={AppPrivacy} />
      <Route path='/terms-and-conditions' component={TermsAndConditions} />
      <Route path='/demo' component={AppDownload} />
      <Route path='/demo-m' component={AppDownloadMasterCard} />
      <Route path='/demo-w' component={AppDownloadWoccu} />
      <Route path='/woccu-demo' component={WoccuAppDownload} />
    </Switch>
  );
}

export default App;
