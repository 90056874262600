import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import { OverlapPartner } from '../partners/partners.component';
import { HeaderOverlap } from '../../../components/essentials/bar-component/bar.component';
import playing from '../../../assest/15 - About - Company/Company.png';
import '../../../components/essentials/bar-component/bar.styles.scss';
import './about-earthid.styles.scss';
import linkedin from '../../../assest/Home/LinkedIn.png';

// import Shiv from '../../../assest/15 - About - Company/Shiv.jfif';
// import Amit from '../../../assest/15 - About - Company/amit.jpg';
// // import Debajani from '../../../assest/15 - About - Company/Debajani.jfif';
// import Vishal from '../../../assest/15 - About - Company/Vishal.jfif';
// import Rahul from '../../../assest/15 - About - Company/Rahul.jfif';
// // import HarshaVardhan from '../../../assest/15 - About - Company/harsha.png';
// // import Anvee from '../../../assest/15 - About - Company/Anvee.jfif';
// import Gerard from '../../../assest/15 - About - Company/Gerard.jfif';
// import Andrew from '../../../assest/15 - About - Company/andrew.jpg';
// import Sarath from '../../../assest/15 - About - Company/Sharat.jpg';
// import Jillian from '../../../assest/15 - About - Company/Jillian-Godsil.jpg';
// import Ingrid from '../../../assest/15 - About - Company/Ingrid.jpg';
export const AboutEarthId = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Company' uris='Company' />
    <OverlapPartner
      title='Championing A Secure And Seamless Universal ID Solution'
    >
      <div className='content-partners about-company'>
        <div className='text-content-partner'>
          <div className='text-content-block'>
            <p className='text-content-earth'>
              EarthID is an award-winning Decentralized Identity Management platform that allows
              secure and frictionless exchange and verification of identity information.</p>
            <p className='text-content-earth'>EarthID helps
            organisations reduce identity frauds, and save time and costs associated with customer
            onboarding processes, while ensuring privacy and regulatory compliance. Powered by
            third-generation DLT, Artificial Intelligence and latest biometric security, EarthID
            provides a Universal ID Solution that is resistant to cyberattacks and compliant with
            global Data Regulations like GDPR and PDPB.
          </p>
            <p className='text-content-earth'>
              We have developed an innovative <strong>Universal ID Solution</strong> with an aim to simplify the
            identity management process to provide seamless access to online as well as offline
            services across industries. EarthID&apos;s mission is to eradicate the monolithic
            <strong> Identity Management Solutions</strong> which are highly prone to cyber-attacks. Using
            state-of-the-art technologies, we ensure that we offer a robust digital solution to our
            customers that is <strong>Secure, Seamless </strong> and <strong>Trustworthy</strong>.
          </p>
            <p className='text-content-earth'>
              EarthID has a team of global experts across India, UK, US and expanding.
          </p>
          </div>
          <div className='text-content-block'>
            <h4 className='text-content-sub-title'>Vision</h4>
            <p className='text-content-earth'>
              <strong>Our vision is an inclusive Earth powered by Self-Sovereign Digital Identity</strong>
            </p>
            <p className='text-content-earth'>
              We are working towards providing a secure self-sovereign digital identity to all human beings, irrespective of their economic, political, and social status.
            </p>
          </div>
        </div>
        <img src={playing} className='img-for-company' alt='partner-img' />
      </div>
    </OverlapPartner>
    <div className='header-overlap-earthid'>
      <HeaderOverlap
        title='INTRODUCING'
        platform='Self Sovereign Identity'
        kyc='Creating an identity ecosystem that puts the user in control of their data'
        url='/ssi'
      />
    </div>
    <EarthidBox title='Thought Leadership' />
    <Getstarted/>
    <Footer />
  </div>
);

const EarthidBox = props => {
  return (
    <>
    </>
    // <div className='Box-Earth-id'>
    //   <p className='title-earth-id'>{props.title}</p>
    //   <div className='first-flex-div'>
    //     <MediumCard
    //       icon={Shiv}
    //       title='Shiv Aggarwal'
    //       desc='Chief Executive Officer'
    //       profileUrl='https://www.linkedin.com/in/shiv-aggarwal/'
    //       description='Shiv is a global thought leader in the identity and blockchain space with over 16 years of industry experience. He has been a keynote speaker at various international conferences, including at US capitol and UK parliament. He is also leading government blockchain association in UK & Europe.'
    //     />
    //     {/* <MediumCard
    //       icon={Debajani}
    //       title='Debajani Mohanty'
    //       desc='DID/SSI Expert'
    //       profileUrl='https://www.linkedin.com/in/debajanimohantypmp/'
    //       description='Debajani is a published author of 5 best-seller books in the Blockchain and Identity industries. She has over two decades of experience in analysis, architecture, development in building enterprise applications.'
    //     /> */}
    //     <MediumCard
    //       icon={Vishal}
    //       title='Vishal Dharmawat'
    //       desc="DLT/Hashgraph Expert"
    //       profileUrl='https://www.linkedin.com/in/vishaldharmawat/'
    //       description='Vishal specializes in product ideation, blockchain, distributed ledger (DLT) including Hashgraph and others, smart contracts, crypto exchanges and other niche front end technologies.'
    //     />
    //     <MediumCard
    //       icon={Rahul}
    //       title='Rahul Asati'
    //       desc='Chief Architect'
    //       profileUrl='https://www.linkedin.com/in/rahulasati/'
    //       description='Rahul is an expert at building and driving software products in diversified industries. He is an award-winning Hashgraph ambassador, and seasoned architect with a deep understanding of various technology platforms and domains.'

    //     />
    //     <MediumCard
    //       icon={Sarath}
    //       title='Sharat Chandra'
    //       desc='VP Research and Strategy'
    //       profileUrl='https://www.linkedin.com/in/sharatchandratechevangelist/'
    //       description="Sharat is a Keynote Speaker, Blockchain & Emerging Tech Evangelist and Ecosystem Builder. He advises across sectors, with a focus on Blockchain, Digital Transformation, and Fintech"
    //     />
    //     {/* <MediumCard
    //       icon={HarshaVardhan}
    //       title='Harsha Jain'
    //       desc='Business Development'
    //       profileUrl='https://www.linkedin.com/in/harsha-vardhan-jain-18594461/'
    //       description='HarshaVardhan Jain is the global business head and has 15+ years of impeccable business intelligence. He has worked with blue chip companies across the globe and has insight into market potential and strategies.'
    //     />
    //     <MediumCard
    //       icon={Anvee}
    //       title='Anvee Gupta'
    //       desc='Business Development'
    //       profileUrl='https://www.linkedin.com/in/anvee-gupta-69734b1a1/'
    //       description='Anvee is a versatile and eloquent growth partner, she is skilled in building market position and has expertise in identifying trendsetter ideas by researching industry .'
    //     /> */}
    //     <MediumCard
    //       icon={Gerard}
    //       title='Gerard Dache'
    //       desc='Advisor'
    //       profileUrl='https://www.linkedin.com/in/gerard-dache-60254023/'
    //       description='Gerard founded the Government Blockchain Association (GBA). He has established over 120 GBA chapters, with over 15,000 members around the world. He has delivered public addresses to the United Nations and delivered keynote addresses to audiences of hundreds of blockchain professionals around the world. He has advised national government cabinet officials as well as state and local leaders.'
    //     />
    //     <MediumCard
    //       icon={Amit}
    //       title='Amit Jindal'
    //       desc="Advisor"
    //       profileUrl='https://www.linkedin.com/in/amit-jindal-57911013/'
    //       description='Amit leads the Project Management Group at Apiary, Centre of Excellence for Blockchain Technology, formed in partnership between Government of India and Software Technology of India. He is also the co-founder of one of the fastest growing financial consultancy in India. Amit is also an active investor and mentor in the Indian startup ecosystem. Amit is an FCA (India), CPA(USA), DISA professional.'
    //     />
    //     <MediumCard
    //       icon={Andrew}
    //       title='Andrew Koh'
    //       desc='Advisor'
    //       profileUrl='https://www.linkedin.com/in/andrewkohmw/'
    //       description="Andrew is an award winning thought leader, keynote speaker, industry trendsetter, finance & technology champion. He posses over three decades of experience in Banking and Capital Markets. Andrew has Shared expertise with head of states, ministers, board directors, C-suite executives, experts from central banks, securities, exchanges, government agencies, Fortune 500, China 1000, unicorns, academia."
    //     />
    //     <MediumCard
    //       icon={Ingrid}
    //       title='Dr. Ingrid Vasiliu-Feltes'
    //       desc='Advisor'
    //       profileUrl='https://www.linkedin.com/in/ingrid-vasiliu-feltes-mdmba/'
    //       description="Named one of the top 100 global finance and healthcare leaders and recipient of Teaching, Research Excellence and Women Leadership Awards. Ingrid is an Entrepreneur, Innovation Ecosystem Builder, Speaker, Consultant, Board Advisor and Chief Ethics Officer at the Government Blockchain Association. Founder & CEO of Institute SEI"
    //     />
    //     <MediumCard
    //       icon={Jillian}
    //       title='Jillian Godsil'
    //       desc='Advisor'
    //       profileUrl='https://www.linkedin.com/in/jilliangodsil/'
    //       description="Named one of 100 Global Women Leaders in COVID, 2020 Blockchain Journalist of the year at Uptrennd.com, 2019 Blockchain Journalist at CC Forum London, top 100 Blockchain leaders globally by Lattice80 and 50 Most Influential Women In Blockchain. Jillain freelances for Irish Tech News, Irish Central, The Irish Independent and The Irish Times. She has her own radio shows on DublinCityFM and EastCoastFM"
    //     />
        
    //   </div>
    // </div>
  );
};

// className earthCard = (props) => {
//   return (
//     <div className='earthCard' />
//   );
// };
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-about'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
          About / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};

export const MediumCard = ({ icon, desc, title, profileUrl, description }) => {
  return (
    <div className='leadership-profile'>
      <img src={icon} className='profile-icon' alt='[profile]' />
      <p className='profile-title'>{title}</p>
      <p className='profile-desc'>{!desc ? 'right now description is not available' : desc}</p>
      <p className='profile-desc-cont'>{!description ? 'right now description is not available' : description}</p>
      <a href={profileUrl} className='linkedInIcon' target='_new'>
        <img src={linkedin} alt='an-img-link'></img>
      </a>
    </div>
  );
};
