import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';

import phoneImg from '../../../assest/26-Digital Credentials/Description_Section_Resized.png';
import kysBulletPoint from '../../../assest/2 - Solutions - Platforms/secure-payment.svg';
import photoOnPhone from '../../../assest/26-Digital Credentials/howsolwoks.png'
import benefits from '../../../assest/26-Digital Credentials/EOTY.png'
import './digital-credentials.scss';
//import { OverlapBoxTechno } from '../../technopage/techno.component';
import { MoreCard, OverlapBoxFeature } from '../features-page/features-page.component';

import education from '../../../assest/26-Digital Credentials/icons/education.png';
import profOrg from '../../../assest/26-Digital Credentials/icons/prof orgs.png';
import workforce from '../../../assest/26-Digital Credentials/icons/workforce.png';
import vital from '../../../assest/26-Digital Credentials/icons/vital records.png';
import health from '../../../assest/26-Digital Credentials/icons/health records.png';
import finance from '../../../assest/26-Digital Credentials/icons/fin creds.png';
import govId from '../../../assest/26-Digital Credentials/icons/gov ids.png';
import membership from '../../../assest/26-Digital Credentials/icons/membership.png';

import complaint from '../../../assest/25 - Age Verification/icons/compliant.png';
import privacy from '../../../assest/25 - Age Verification/icons/privacy preserving.png';
import seamless from '../../../assest/25 - Age Verification/icons/seamless integration.png';
// import global from '../../../assest/25 - Age Verification/global coverage.png'
// import dataAnalytics from '../../../assest/25 - Age Verification/9624 man.png'

//import {  MediumCard } from '../platformpage/platform.component';
//import { OverlapBox } from '../../../components/essentials/overlap-box/overlap-box.component';

export const DigitalCredentials = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Verifiable Digital Credentials' uris='Verifiable Digital Credentials' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Secure and Decentralized Credential Management.'
    >
      <p>
      Verifiable digital credentials are essential for various industries to ensure security, compliance, and efficiency. 
      Whether you need to verify academic degrees, professional certifications, or personal identities, digital credentials offer a secure, tamper-proof solution. Traditional methods often involve cumbersome processes and require extensive personal information, which can be a barrier to user trust and efficiency. EarthID&apos;s verifiable digital credentials provide a streamlined, secure, and user-friendly approach to managing and verifying credentials across multiple industries.
      </p>
      <p>
      You don&apos;t need to know someone&apos;s detailed personal information to verify their credentials. 
      Our solution allows users to confirm their qualifications without revealing sensitive data, promoting privacy for everyone.
      </p>
      <p>
      Effortlessly verify credentials with our comprehensive solution, designed to detect fake certificates and prevent advanced digital fraud methods like spoofing and deepfakes. 
      Our system ensures compliance with industry standards, protecting your organization from fraudulent claims.
      </p>
      <p>
      Our state-of-the-art technology employs privacy-preserving verifications, enabling users to verify their credentials without sharing personal details. 
      This enhances privacy and builds trust, making our solution ideal for both in-person and online verification, ensuring compliance and boosting user confidence.
      </p>
    </OverlapBoxTechno>
    <OverlapBoxFeature title='How Our Solution Works'>
    <p className='industries-text'>
        Verifiable digital credentials are essential for various industries to ensure security, compliance, and efficiency. 
        Whether you need to verify academic degrees, professional certifications, or personal identities, digital credentials offer a secure, tamper-proof solution. Traditional methods often involve cumbersome processes and require extensive personal information, which can be a barrier to user trust and efficiency. 
        EarthID&apos;s verifiable digital credentials provide a streamlined, secure, and user-friendly approach to managing and verifying credentials across multiple industries.
        </p>
        <div > <img src={photoOnPhone} className='kys-platform-imagee1' alt='MoreCardIcon' /></div>
        <MoreBox />

        
      </OverlapBoxFeature>
      <OverlapBoxIndustry title='Industry Use Cases' className=' overlap-box-industry'>
        <p className='industries-text'>
        Verifiable digital credentials are essential for various industries to ensure security, compliance, and efficiency. 
        Whether you need to verify academic degrees, professional certifications, or personal identities, digital credentials offer a secure, tamper-proof solution. Traditional methods often involve cumbersome processes and require extensive personal information, which can be a barrier to user trust and efficiency. 
        EarthID&apos;s verifiable digital credentials provide a streamlined, secure, and user-friendly approach to managing and verifying credentials across multiple industries.
        </p>
        <div className='cards-div-industries'>
          <LargeCard
            img={education}
            title='Education'
            desc="Educational institutions can issue and verify academic credentials such as degrees, diplomas, and certificates digitally, automating transcript requests and facilitating international student mobility. This not only enhances the verification process but also reduces the administrative burden on institutions."
          />
          <LargeCard
            img={profOrg}
            title='Professional Organizations'
            desc="Professional organizations can securely manage memberships and certifications, ensuring ongoing education and professional development are verified. This helps maintain trust in professional designations and supports continuous education initiatives."
          />
          <LargeCard
            img={workforce}
            title='Workforce'
            desc="Employers can streamline hiring processes by quickly verifying employee certifications and skills. Maintaining secure and up-to-date credential records helps ensure that employees&apos; qualifications are always current and verifiable."
          />
          <LargeCard
            img={vital}
            title='Vital Records'
            desc="Government agencies and related organizations can digitally manage birth, marriage, and death certificates, ensuring these vital records are tamper-proof and easily verifiable. This approach also enhances the protection of sensitive personal information."
          />
          <LargeCard
            img={health}
            title='Health Records'
            desc="Healthcare providers and organizations can verify vaccination records and other health credentials securely. Managing health insurance cards and ensuring compliance with healthcare regulations becomes more efficient and secure."
          />
          <LargeCard
            img={finance}
            title="Financial Credentials"
            desc="Financial institutions can securely verify and share credit scores and manage financial documents. Ensuring compliance with financial regulations while protecting sensitive information is streamlined through digital credentials."
          />
          <LargeCard
            img={govId}
            title='Government IDs'
            desc='Governments can manage and verify passports, driver&apos;s licenses, and national ID cards, ensuring these identification documents are tamper-proof and compliant with international standards, enhancing trust and security.'
          />
          <LargeCard
            img={membership}
            title='Memberships and Event Participation'
            desc="Clubs, associations, and event organizers can manage memberships and issue event participation credentials digitally. This enhances user engagement and trust through shareable and verifiable digital credentials."
          />          
        </div>
      </OverlapBoxIndustry>
      <OverlapBoxFeature  title='Solution Benefits'>
        <EarthidBox />
      </OverlapBoxFeature>
      <OverlapBoxFeature title='Enabling Trust with Our Verifiable Digital Credential Solution'>
      <div className='platform-content'>
          <p className='cards-div-title'>
          By integrating our verifiable digital credential solution, you can ensure trust and reliability in credential management while maintaining the highest standards of security, privacy, and efficiency.
        </p>
          <div className='cards-div-platform'>
            <MediumCard
              icon={complaint}
              title='Compliant'
              desc='Our verifiable digital credential solution meets top compliance and security standards. We adhere to GDPR for privacy protection and hold ISO 27001 certification for information security. Additionally, we comply with the UK DIATF standards and follow W3C standards for Decentralized Identifiers (DID) and Verifiable Credentials (VC). These certifications ensure our solution offers maximum data security, privacy, and trust, making digital credentials reliable and secure.'
            />
            <MediumCard
              icon={privacy}
              title='Privacy Preserving'
              desc='We believe privacy is paramount. Our innovative solution ensures that users can verify their credentials without revealing sensitive personal information, such as their name or date of birth. This approach protects personal data, enhances user trust, and ensures compliance with privacy regulations. By prioritizing privacy, we provide a secure and trustworthy credentialing process that respects user confidentiality and safeguards their information.'
            />
            <MediumCard
              icon={seamless}
              title='Seamless Integration'
              desc='Our solution is designed for optimal efficiency, offering smooth, seamless integration into your existing systems. Utilizing our advanced SDK and APIs, our digital credential verification enhances the user experience by ensuring uninterrupted engagement and a frictionless verification process. This integration simplifies deployment and ensures that credential verification fits seamlessly into your workflow, providing a consistent and efficient user journey.'
            />
          </div>
        </div>
      </OverlapBoxFeature>
      {/* <SectionRight
        title="Global Coverage"
        title1="11K+ Documents Supported"
        content1= "EarthID supports over 11,500 identity documents from more than 230 countries and territories. Our extensive global reach allows you to verify your user's age from anywhere in the world."
        
        title2="45+ Languages and Dialects Supported"
       content2= "We have tailored our end-user flows to cater to the world's largest markets, providing support for 45+ languages and dialects. By offering a wide range of languages, we ensure that we can serve a diverse customer base. Our commitment to expanding our language offerings helps us meet the strategic goals of our partners."
        imgPath={global}
      ></SectionRight>
      <SectionLeft
        title="Advanced Security Measures"
        title1="Device and Network Analytics"
        content1= "As part of our comprehensive fraud prevention measures, EarthID analyzes the device and network used during a verification session, assigning each a unique fingerprint. This, along with hundreds of other parameters and checks for inconsistencies in personal data on the ID, ensures a robust verification process before delivering results."
        
        title2="Liveness Detection"
       content2= "EarthID&apos;s advanced liveness detection technology determines if a session or user is real or a spoof attempt, all without requiring extra steps from your customers. This seamless process enhances security while maintaining a smooth user experience."
        imgPath={dataAnalytics}
      ></SectionLeft> */}
    <Getstarted/>
    <Footer />
  </div>
);

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-distributee'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Solutions / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};

const MoreBox = props => {
    return (
      <div className='kys-div'>
        <div className='kys-platform-content'>
          <MoreCard icon={kysBulletPoint} title='Credential Issuance'
            desc='Organizations, institutions, or individuals can issue digital credentials using our platform. These credentials are securely encoded with essential information, such as the issuer&apos;s identity, recipient&apos;s details, and the specific achievement or qualification. Our system ensures the authenticity of the data through advanced cryptographic techniques.'
          />
          <MoreCard icon={kysBulletPoint} title='Secure Storage'
            desc="Once issued, the digital credentials are stored in a secure, tamper-proof environment. Each credential is assigned a unique identifier and is stored on a decentralized blockchain network. This ensures that the credential remains immutable and can be independently verified at any time."
          />
          <MoreCard icon={kysBulletPoint} title='Verification Process'
            desc='To verify a credential, the recipient or a third party can scan a QR code or enter a unique verification link provided with the digital credential. Our system then retrieves the credential&apos;s data from the blockchain and verifies its authenticity. This process confirms that the credential is legitimate and has not been altered since its issuance.'
          />
          <MoreCard icon={kysBulletPoint} title='User-Friendly Access'
            desc='Recipients of digital credentials can easily access and manage their credentials through our user-friendly web and mobile interfaces. They can share their credentials with employers, educational institutions, or other relevant parties with just a few clicks, ensuring that their achievements are recognized and verified swiftly.'
          />
        </div>
        <div className='kys-platform-content'>
          <MoreCard icon={kysBulletPoint} title='Interoperability'
            desc='Our solution is designed to be compatible with various standards and frameworks for digital credentials. This interoperability ensures that our credentials can be used and verified across different platforms and systems, enhancing their utility and acceptance globally.'
          />
          <MoreCard icon={kysBulletPoint} title='Privacy and Security'
            desc='We prioritize the privacy and security of our users. Personal data and credential information are encrypted and protected by stringent security protocols. Our platform complies with international data protection regulations, ensuring that users&apos; information is handled responsibly and ethically.'
          />
          <MoreCard icon={kysBulletPoint} title='Real-Time Updates'
            desc='Issuers can update or revoke credentials in real-time, ensuring that the information remains current and accurate. This feature is particularly useful for credentials that may expire or need periodic renewal, such as professional certifications or licenses.'
          />
        </div>
       
  
      </div>
  
  
    );
  };

  const OverlapBoxIndustry = props => {
    return (
      <div className='overlap-boxx'>
        <p className='box-title'>{props.title}</p>
        {props.children}
      </div>
    );
  };

  const LargeCard = ({ img, desc, title }) => {
    return (
      <div className='large-card'>
        <img src={img} className='large-card-imgg' alt='largeCardImg' />
        <p className='large-card-title'>{title}</p>
        <p className='large-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    );
  };

  const EarthidBox = props => {
    return (
      <div className='kys-div'>
        <div className='kys-platform-content'>
          <KYSCard icon={kysBulletPoint} title='Enhanced Security'
            desc='Our platform ensures tamper-proof digital credentials using advanced blockchain technology, guaranteeing authenticity and significantly reducing the risk of fraud.'
          />
          <KYSCard icon={kysBulletPoint} title='Effortless Verification'
            desc='Users can seamlessly verify their credentials through cryptographic proof, eliminating manual checks. Verification completes in seconds, reducing administrative hassle.'
          />
  
          <KYSCard icon={kysBulletPoint} title='Convenience and Accessibility'
            desc="Access and manage your credentials anytime and anywhere through our intuitive web and mobile interfaces, providing unparalleled convenience."
          />
           <KYSCard icon={kysBulletPoint} title='Enhanced Privacy'
            desc='EarthID uses privacy-preserving verifications without storing personal data, significantly enhancing user privacy and ensuring legal and regulatory compliance.'
          />
          <KYSCard icon={kysBulletPoint} title='Efficiency and Speed'
            desc='Automated processes allow for real-time verification, streamlining the issuance and authentication of digital credentials efficiently and swiftly.'
          />
        </div>
        <div className='kys-platform-content'>
          
          <KYSCard icon={kysBulletPoint} title='Seamless Experience'
            desc='EarthID offers a streamlined, frictionless user experience, ensuring convenient one-click issuance and verification of credentials across various platforms and devices.'
          />
          <KYSCard icon={kysBulletPoint} title='Global Recognition'
            desc='Designed to be interoperable with international standards, our digital credentials are recognized and accepted worldwide, enhancing their global utility.'
          />
          <KYSCard icon={kysBulletPoint} title='Precise and Unbiased Results'
            desc='Leveraging verified data, EarthID offers precise and unbiased credential verification across all demographics, addressing biases present in other solutions.'
          />
           <KYSCard icon={kysBulletPoint} title='Enforcement and Compliance'
            desc='Ensure compliance with legal requirements and industry standards, boosting user confidence with a robust verification system that prevents unauthorized access.'
          />
           <KYSCard icon={kysBulletPoint} title='Scalable, Shareable, and Efficient'
            desc='Our solution is scalable without sacrificing productivity. Credentials can be socially shared, making them accessible and verifiable across platforms, enhancing marketing, enrolment, and engagement.'
          />
        </div>
        <div className='kys-platform-img'> <img src={benefits} className='kys-platform-imagee' alt='kysCardIcon' /></div>
  
      </div>
  
  
    );
  };


  // const SectionRight = props => {
  //   return (
  //     <div className='feature-box-right'>
  //       <p className='title-feature'>{props.title}</p>
  //       <div className="display-flex">
  //         <div className='feature-box-content'>
  //           <div className='overallcontent'>
  //           <p className='title1-feature'>{props.title1}</p>
  //         <p className='content1-feature'>{props.content1}</p>
  //         <p className='title1-feature'>{props.title2}</p>
  //         <p className='content1-feature'>{props.content2}</p>
  //           </div>
          
  //         </div>
  //         <div className='feature-box-image'>
  //           <img src={props.imgPath} />
  
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const SectionLeft = props => {
  //   return (
  //     <div className='feature-box-left'>
  //       <p className='title-feature'>{props.title}</p>
  //       <div className="display-flex">
  //       <div className='feature-box-image'>
  //           <img src={props.imgPath} />
  
  //         </div>
  //         <div className='feature-box-content'>
  //           <div className='overallcontent'>
  //           <p className='title1-feature'>{props.title1}</p>
  //         <p className='content1-feature'>{props.content1}</p>
  //         <p className='title1-feature'>{props.title2}</p>
  //         <p className='content1-feature'>{props.content2}</p>
  //           </div>
          
  //         </div>
          
  //       </div>
  //     </div>
  //   );
  // };

 const KYSCard = ({ icon, desc, title }) => {
    return (
      <div className='kys-card'>
        <div className="key-icon-col"><img src={icon} className='kys-card-icon' alt='kysCardIcon' /></div>
        <div className="key-text-col">
          <p className='kys-card-title'>{title}</p>
          <p className='kys-card-desc'>{!desc ? 'right now description is not available' : desc}</p>
        </div>
      </div>
    );
  };

 const OverlapBoxTechno = ({ img, title, children }) => {
    return (
      <div className='overlap-box-technoo'>
        <img src={img} alt='phone-img' className='techno-img' />
        <div className='techno-content'>
          <p className='techno-title'>{title}</p>
          <div className='rest-para'>{children}</div>
        </div>
      </div>
    );
  };


  const MediumCard = ({ icon, desc, title }) => {
    return (
      <div className='medium-card'>
        <img src={icon} className='medium-card-icon-platform' alt='mediumCardIcon' />
        <p className='medium-card-title'>{title}</p>
        <p className='medium-card-desc-platform'>{!desc ? 'right now description is not available' : desc}</p>
      </div>
    );
  };