import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBoxTechno } from '../techno.component';
import phoneImg from '../../../assest/phoneImg/Distributed  Ledger.png';
import './distributed.styles.scss';

export const DistributeLedger = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Distributed Ledger' uris='Distributed Ledger' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Securing The Future Of Identity Authentication With Distributed Ledger.'
    >
      <p>
        Distributed Ledger technology holds the key foundation in creating a decentralized digital
        identity ecosystem. We at EarthID understand the significance of having control over your
        data as a user. EarthID uses 3rd generation public distributed ledger technology, Hedera
        Hashgraph, to provide users with more proactive control over their data.
      </p>
      <p>
        Distributed Ledger Technology enables secure management and storage of digital identities on
        the EarthID platform by providing a tamper-proof platform. Using the combination of DLT and
        Cryptography, it is possible for EarthID to provide decentralized data solutions,
        maintaining trust, and data integrity at the same time.
      </p>
      <p>
        EarthID’s DLT based identity management solution lets users control when, where, and with
        whom they share their data. Our robust system allows user to store their credentials on
        their device itself, maintaining self-sovereign identity. To learn more about this, talk to
        our experts.
      </p>
    </OverlapBoxTechno>
    <Getstarted/>
    <Footer />
  </div>
);

const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-distribute'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Technology / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
