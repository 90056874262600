import React from 'react';
import './body-content.styles.scss';
import user_privacy from '../../../assest/home-card/User Privacy.svg';
import ecosystem from '../../../assest/home-card/trusted ecosystem (1).svg';
import customer from '../../../assest/home-card/Strong Customer Authentication (1).svg';

export const BodyContentCard = () => (
  <div className='body-content'>
    <div className='body-content_text'>
      <p className='body-content_text-1'>Why Is EarthID The Right Solution For You? </p>
      <p className='body-content_text-2'>
      We establish trust between you and your customers through our innovation and intelligence.
      </p>
    </div>
    <div className='body-content_card'>
      <div className='body-content_card-1'>
        <img src={user_privacy} alt='body-card_icon' className='body-content_card-icon' />
        <p className='body-content_card-title'>
          User Privacy With Self Sovereign Identity
        </p>
        <p className='body-content_card-desc text-center'>
        No more sacrifices. EarthID enables identity issuance and verification so you can quickly onboard more customers in a secure, frictionless and cost efficient manner.
        </p>
      </div>
      <div className='body-content_card-1'>
        <img src={ecosystem} alt='body-card_icon' className='body-content_card-icon' />
        <p className='body-content_card-title'>
          A Trusted Ecosystem For Global Businesses
        </p>
        <p className='body-content_card-desc text-center'>
        EarthID lets you accelerate customer acquisition and transcend borders without having to worry about security and compliance. Enhance customer trust by offering a fraud-proof solution powered by EarthID
        </p>
      </div>
      <div className='body-content_card-1'>
        <img src={customer} alt='body-card_icon' className='body-content_card-icon' />
        <p className='body-content_card-title'>
          Better Corroboration With Strong Customer Authentication
        </p>
        <p className='body-content_card-desc text-center'>
        EarthID uses Strong Customer Authentication as per the recommendations by EBA to help fight against identity fraud. Future-proof your business by moving to EarthID’s all-new biometric-based multi-factor authentication for better identity corroboration.
        </p>
      </div>
    </div>
  </div>
);
