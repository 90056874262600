import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBoxTechno } from '../techno.component';
import phoneImg from '../../../assest/phoneImg/Behavioral Biometrics.png';
import './behavioral.styles.scss';

export const Behavioral = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Behavioral Biometrics' uris='Behavioral Biometrics' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Adaptive Authentication For A Personalized And Convenient User Experience'
    >
      <p>
      With the fast-changing cyber dynamics, identity is a commodity that needs to be secured at all times. Physical biometric authentication alone can not suffice for the sophisticated identity thefts emerging almost daily. And, this calls for highly-adaptive, Deep Learning Technology to be integrated with the existing verification solutions.      </p>
      <p>
      EarthId uses Behavioral Biometrics to identify and recognize patterns of user behavior that are variable over time. Behavioral Biometrics uses Machine Learning, Artificial Intelligence, and BigData to aggregate user interactions and creates a unique cyber-DNA for each authentic user. This way using the behavioral identifiers, EarthId’s application can immediately recognize a sudden change in user behavior. Behavioral Biometrics transparently works at the backend while the user is interacting with the application.       </p>
      <p>
      With EarthId’s Behavioral Biometrics technology, strengthen the defense against identity fraud, and verify your users transparently every time they log in, without hampering their user experience.      </p>
    </OverlapBoxTechno>
    <Getstarted/>
    <Footer />
  </div>
);

//dafh
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-bb'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Technology / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
