import React from 'react';
import { NavBar } from '../../../components/essentials/navbar/navbar.component';
import { Footer } from '../../../components/essentials/footer/footer.component';
import {Getstarted} from '../../../components/essentials/get-started/getstarted.component';
import { OverlapBoxTechno } from '../techno.component';
import phoneImg from '../../../assest/phoneImg/Zero Knowledge Proof.png';
import './zero-proofs.styles.scss';

export const ZeroProofs = () => (
  <div className='blogs-main'>
    <NavBar />
    <HeaderHalf title='Zero-Knowledge Proofs' uris='Zero-Knowledge Proofs' />
    <OverlapBoxTechno
      img={phoneImg}
      title='Revolutionizing The Way Data Is Collected, Used, And Transacted With'
    >
      <p>
      In the course of capturing and verifying identity information, a lot of confidential information is shared across parties. Hence, making consumer privacy and data protection a growing concern for the users availing <strong>identity management services</strong>.
      </p>
      <p>
      EarthID uses Zero-Knowledge Proofs technology to put users back in control of their data by letting users disclose only certain information that needs to be validated rather than exposing the data itself. This practice is immensely beneficial for industries like finance and healthcare where transactions can be enabled while safeguarding their data privacy. Zero-Knowledge Proof technology uses cryptographic algorithms to facilitate the transmission of sensitive identity information with better security.
      </p>
      <p>
      Maintain privacy in today’s data-driven world with EarthId’s Zero-Knowledge Proofs technology and own your fundamental right to privacy and data ownership. Unleash the potential of a data-based economy without having to give up on your privacy.
      </p>
    </OverlapBoxTechno>
    <Getstarted/>
    <Footer />
  </div>
);

//dafh
const HeaderHalf = ({ title, uris }) => {
  return (
    <div className='headerHalf-main-kp'>
      <div className='headerHalf-content'>
        <p className='headerHalf-title'>{title}</p>
        <p className='headerHalf-uris'>
        Technology / <span className='uris-class'>{uris}</span>
        </p>
      </div>
    </div>
  );
};
